import React from 'react'
import ReactDOM from 'react-dom'

const Overlay = ({isOpen, onClose, children}) => {
	if (!isOpen) return null

	return ReactDOM.createPortal(
		<div className='fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4'>
			<div className='bg-white rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto relative'>
				<button onClick={onClose} className='absolute top-4 right-4 text-gray-500 hover:text-gray-700'>
					✕
				</button>
				<div className='p-6'>{children}</div>
			</div>
		</div>,
		document.body
	)
}

export default Overlay
