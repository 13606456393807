import React, {useContext, useEffect, useRef, useState} from 'react' // Added useState
import HeroSection from '../../sections/Hero'
import ProsSection from '../../sections/Pros'
import HowItWorksSection from '../../sections/HowItWorks'
// eslint-disable-next-line no-unused-vars
import Gallery2 from '../../sections/Gallery2'
import EnoughTalk from '../../sections/EnoughTalk'
import FAQSection from '../../sections/FAQ'
// import Contact from '../../sections/Contact'
// import Footer from '../../sections/Footer'
import {NavContext} from '../index' // Import the context
import {ContactSection} from '../../sections/Contact/ContactSection'
// eslint-disable-next-line no-unused-vars
import ScrollToTop from '../../utils/scrollToTop'
import {useLocation, useNavigate} from 'react-router-dom'
import {scrollIntoView} from '../../utils/scroll_into_view'
import Gallery3 from '../../sections/Gallery3'
// import {useNotification} from '../../context/NotificationContext'
import {Helmet} from 'react-helmet-async' // Add this import
import TestimonyList from '../../sections/Testimony/TestimonyList'
// import ogImageJpg from '../../images/gallery/showcase.jpg' // Fallback JPG version

const Home = () => {
	const location = useLocation()

	const navigate = useNavigate()
	// const {setToast} = useNotification()
	useEffect(() => {
		// Add a slightly longer delay to ensure components are mounted
		const timeoutId = setTimeout(() => {
			if (location.hash) {
				// setToast('scrolling to gallery')
				scrollIntoView(null, location.hash)
				navigate(location.pathname, {replace: true})
			}
		}, 500) // Increased delay to 500ms

		return () => clearTimeout(timeoutId) // Cleanup timeout
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.hash]) // Include all dependencies

	const heroRef = useRef(null) // Reference to the hero section
	// eslint-disable-next-line no-unused-vars
	const {setOnHeroSection, onHeroSection, setDefaultShow, setHideOnScroll} = useContext(NavContext) // Access the context
	const [isNavVisible, setIsNavVisible] = useState(onHeroSection) // Local state for navbar visibility

	useEffect(() => {
		setHideOnScroll(true)
		setDefaultShow(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// useEffect(() => {
	// 	// Check if there's a hash in the URL
	// 	if (location.hash) {
	// 		const id = window.location.hash.substring(1) // Get the ID from the hash
	// 		const element = document.getElementById(id) // Find the element by ID
	// 		if (element) {
	// 			element.scrollIntoView({behavior: 'smooth'}) // Scroll to the element
	// 		}
	// 		scrollIntoView()
	// 	}
	// }, []) // Run this effect only once on load

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					const heroVisibleRatio = entry.intersectionRatio
					// const isHeroVisible = heroVisibleRatio > 0 // Check if hero is visible

					if (heroVisibleRatio === 0) {
						// Show navbar when hero is 100% out of view
						setOnHeroSection(false)
						setIsNavVisible(true)
					} else if (heroVisibleRatio < 0.1 && isNavVisible) {
						// Hide navbar when 10% or more of hero is visible
						setOnHeroSection(true)
						setIsNavVisible(false)
					} else if (heroVisibleRatio >= 0.1 && !isNavVisible) {
						// Keep navbar hidden while hero is visible
						setOnHeroSection(true)
						setIsNavVisible(false)
					}
				})
			},
			{threshold: [0, 0.1]} // Trigger at 0% and 10% visibility
		)

		const nodeRef = heroRef?.current
		if (nodeRef) observer.observe(nodeRef)
		return () => observer.disconnect()
	}, [heroRef, setOnHeroSection, isNavVisible])

	// Get the full URL for the OG image
	// const ogImageUrlJpg = `${window.location.origin}${ogImageJpg}`

	return (
		<>
			<Helmet>
				<title>Knapp Cartoons | Custom Cartoon Caricatures & Character Design</title>
				<meta
					name='description'
					content='Turn yourself, your family, or your team into delightful cartoon characters. Professional caricatures and character designs that capture your unique personality and style.'
				/>

				{/* Open Graph tags */}
				<meta property='og:site_name' content='Knapp Cartoons' />
				<meta property='og:url' content='https://knappcartoons.com/'></meta>
				<meta property='og:title' content='Knapp Cartoons | Custom Cartoon Caricatures & Character Design' />
				<meta
					property='og:description'
					content='Turn yourself, your family, or your team into delightful cartoon characters. Professional caricatures and character designs that capture your unique personality and style.'
				/>
				<meta property='og:type' content='website' />
				{/* <meta property='og:image' content={ogImageUrlJpg} /> */}
				{/* <meta property='og:image:width' content='1200' />
				<meta property='og:image:height' content='630' /> */}

				{/* Twitter Card tags */}
				{/* <meta name='twitter:card' content='summary_large_image' /> */}
				{/* https://developer.x.com/en/docs/x-for-websites/cards/overview/summary-card-with-large-image */}
				<meta name='twitter:card' content='summary' />
				<meta property='twitter:card' content='summary_large_image' />

				<meta property='twitter:title' content='Knapp Cartoons | Custom Cartoon Caricatures & Character Design' />
				<meta name='twitter:title' content='Knapp Cartoons | Custom Cartoon Caricatures & Character Design' />
				<meta
					property='twitter:description'
					content='Turn yourself, your family, or your team into delightful cartoon characters. Professional caricatures and character designs that capture your unique personality and style.'
				/>
				<meta
					name='twitter:description'
					content='Turn yourself, your family, or your team into delightful cartoon characters. Professional caricatures and character designs that capture your unique personality and style.'
				/>
				{/* <meta property="twitter:image" content="Twitter link preview image URL"/> */}
				{/* <meta property='twitter:image:alt' content={ogImageUrlJpg} /> */}
			</Helmet>
			<div className='fade-this'>
				{/* <ScrollToTop /> */}
				{/* <Nav defaultShow={false} onHeroSection={onHeroSection} hideOnScroll={true} /> */}
				{/* Conditionally render Nav */}
				<div ref={heroRef}>
					<HeroSection />
				</div>
				<ProsSection />
				<TestimonyList />

				<Gallery3 />
				<HowItWorksSection />
				<EnoughTalk />
				<FAQSection />
				<ContactSection />
			</div>
		</>
	)
}

export default Home
