import React, {useRef, useEffect, useState} from 'react'
import testimonyData from './testimonyData.json'
import TestimonyCard from './TestimonyCard'
import Border from '../../components/Border'

// Dynamic image imports
const importAll = (r) => {
	const images = {}
	r.keys().forEach((item) => {
		images[item.replace('./', '')] = r(item)
	})
	return images
}

// Import all images from both testimonials and gallery directories
const testimonialImages = {
	...importAll(require.context('../../images/testimonials', false, /\.(png|jpe?g|svg|webp)$/)),
}

const TestimonyList = () => {
	const scrollRef = useRef(null)
	const isHoveringRef = useRef(false)
	const [isPaused, setIsPaused] = useState(false)
	const [isInitialized, setIsInitialized] = useState(false)
	const [isDragging, setIsDragging] = useState(false)
	const [startX, setStartX] = useState(0)
	const [scrollLeft, setScrollLeft] = useState(0)

	// Process testimonial data with correct image URLs
	const processedTestimonials = testimonyData.map((testimony) => {
		const photoFilename = testimony.photo_url ? testimony.photo_url.split('/').pop() : null
		return {
			name: testimony.name,
			nameAlt: testimony.name_he,
			testimonial: testimony.testimonial,
			testimonialAlt: testimony.testimonial_he,
			photoUrl: photoFilename ? testimonialImages[photoFilename] : null, // Use null if no photo
			isAltRtl: testimony.is_alt_rtl,
		}
	})

	// Add extra items for smooth looping
	const extendedTestimonials = [...processedTestimonials]

	// Handle mouse wheel scrolling
	const handleWheel = (e) => {
		if (scrollRef.current && scrollRef.current.contains(e.target)) {
			// Prevent default only if the mouse is over the testimony container
			e.preventDefault()
			scrollRef.current.scrollLeft += e.deltaY
			setIsPaused(true)
			setTimeout(() => setIsPaused(false), 2000)
		}
	}

	// Handle mouse down event to start dragging
	const handleMouseDown = (e) => {
		if (scrollRef.current) {
			setIsDragging(true)
			setStartX(e.pageX - scrollRef.current.offsetLeft)
			setScrollLeft(scrollRef.current.scrollLeft)
		}
	}

	// Handle mouse move event to scroll while dragging
	const handleMouseMove = (e) => {
		if (!isDragging || !scrollRef.current) return
		e.preventDefault()
		const x = e.pageX - scrollRef.current.offsetLeft
		const walk = (x - startX) * 1.5 // Adjust scroll speed
		scrollRef.current.scrollLeft = scrollLeft - walk
	}

	// Handle mouse up or leave event to stop dragging
	const handleMouseUpOrLeave = () => {
		setIsDragging(false)
	}

	// Handle mouse enter to pause scrolling
	const handleMouseEnter = () => {
		isHoveringRef.current = true
		setIsPaused(true)
	}

	// Handle mouse leave to resume scrolling
	const handleMouseLeave = () => {
		isHoveringRef.current = false
		setIsPaused(false)
	}

	// Center the content and handle seamless looping
	useEffect(() => {
		const scrollContainer = scrollRef.current
		if (!scrollContainer || isInitialized) return

		// Center the content initially
		const centerOffset = scrollContainer.scrollWidth / 2 - scrollContainer.clientWidth / 2
		scrollContainer.scrollLeft = centerOffset
		setIsInitialized(true)

		const handleScroll = () => {
			const maxScroll = scrollContainer.scrollWidth - scrollContainer.clientWidth
			const midpoint = maxScroll / 2

			if (scrollContainer.scrollLeft <= 0) {
				// Scroll to the midpoint when reaching the start
				scrollContainer.scrollLeft = midpoint
			} else if (scrollContainer.scrollLeft >= maxScroll) {
				// Scroll to the midpoint when reaching the end
				scrollContainer.scrollLeft = midpoint
			}
		}

		scrollContainer.addEventListener('scroll', handleScroll)
		return () => scrollContainer.removeEventListener('scroll', handleScroll)
	}, [isInitialized])

	// Ensure auto-scroll respects hover state
	useEffect(() => {
		const scrollContainer = scrollRef.current
		let animationFrameId
		let direction = 1 // 1 for forward, -1 for backward

		const scroll = () => {
			if (!isPaused && scrollContainer && !isHoveringRef.current) {
				scrollContainer.scrollLeft += direction * 0.5 // Adjust speed and direction

				// Reverse direction when reaching the edges
				if (scrollContainer.scrollLeft <= 0 || scrollContainer.scrollLeft >= scrollContainer.scrollWidth - scrollContainer.clientWidth) {
					direction *= -1
				}
			}
			animationFrameId = requestAnimationFrame(scroll)
		}

		animationFrameId = requestAnimationFrame(scroll)

		return () => {
			cancelAnimationFrame(animationFrameId)
		}
	}, [isPaused])

	return (
		<div className='relative overflow-hidden h-[600px] select-none'>
			<h1 className='section-title'>{'Hear From Our Happy Clients!'.toUpperCase()}</h1>
			<div
				ref={scrollRef}
				className='overflow-x-auto overflow-y-hidden cursor-grab active:cursor-grabbing overscroll-x-contain snap-x snap-mandatory scrollbar-hide select-none'
				onWheel={handleWheel}
				onMouseEnter={handleMouseEnter}
				onMouseDown={handleMouseDown}
				onMouseMove={handleMouseMove}
				onMouseUp={handleMouseUpOrLeave}
				onMouseLeave={handleMouseLeave}>
				<div className='flex gap-6 px-4 py-8'>
					{extendedTestimonials.map((testimony, index) => (
						<div key={index} className='flex-none w-[300px]'>
							<TestimonyCard {...testimony} />
						</div>
					))}
				</div>
			</div>
			<div className='pt-12 flex justify-center items-center'>
				<Border />
			</div>
		</div>
	)
}

export default TestimonyList
