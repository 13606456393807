import React, {useEffect, useState} from 'react'
import './testimonyCard.css'
import {FaQuoteLeft, FaQuoteRight} from 'react-icons/fa'
import '../../styles/scrollbar.css'
import Overlay from '../../components/Overlay'

const TestimonyHeader = ({currentName, photoUrl, showAltLanguage, isAltRtl, size = 'small'}) => {
	// Helper function to get initials from name
	const getInitials = (currentName) => {
		return currentName
			.split(' ')
			.map((word) => word[0])
			.join('')
			.toUpperCase()
	}

	const sizes = {
		small: {
			container: 'w-12 h-12',
			text: 'text-lg',
		},
		large: {
			container: 'w-16 h-16',
			text: 'text-2xl',
		},
	}

	return (
		<div className={`flex items-center gap-4 ${showAltLanguage && isAltRtl ? 'flex-row-reverse' : 'flex-row'}`}>
			<div className={`${sizes[size].container} rounded-full border-2 border-e_yellow overflow-hidden bg-white flex-shrink-0 select-none`}>
				{photoUrl ? (
					<img src={photoUrl} alt={`${currentName}'s pic`} className='w-full h-full object-cover object-center select-none' />
				) : (
					<div className='w-full h-full flex items-center justify-center bg-e_yellow text-black font-semibold select-none'>{getInitials(currentName)}</div>
				)}
			</div>
			<h3 className={`${sizes[size].text} font-semibold select-none`} dir={showAltLanguage && isAltRtl ? 'rtl' : 'ltr'}>
				{currentName}
			</h3>
		</div>
	)
}

const TestimonyCard = ({name, nameAlt, testimonial, testimonialAlt, photoUrl, isAltRtl}) => {
	const [showAltLanguage, setShowAltLanguage] = useState(false)
	const [isOverlayOpen, setIsOverlayOpen] = useState(false)
	const [currentText, setCurrentText] = useState(testimonial)
	const [currentName, setCurrentName] = useState(name)

	// Check if alternative language exists
	const hasAltLanguage = Boolean(testimonialAlt && nameAlt)

	// Helper function to truncate text with null check
	const truncateText = (text, maxLength) => {
		if (!text) return ''
		return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text
	}

	useEffect(() => {
		// Update the current text and name based on the selected language
		if (hasAltLanguage && showAltLanguage) {
			setCurrentText(testimonialAlt)
			setCurrentName(nameAlt)
		} else {
			setCurrentText(testimonial)
			setCurrentName(name)
		}
	}, [showAltLanguage, hasAltLanguage, testimonial, testimonialAlt, name, nameAlt])

	return (
		<>
			<div className='relative flex flex-col bg-white/90 shadow-lg rounded-lg overflow-hidden px-6 pt-6 pb-2 max-h-[250px] h-full group'>
				{/* Header with photo and name */}
				<div className='mb-6 flex-shrink-0 select-none '>
					<TestimonyHeader
						currentName={currentName}
						photoUrl={photoUrl}
						showAltLanguage={hasAltLanguage && showAltLanguage}
						isAltRtl={isAltRtl}
						size='small'
					/>
				</div>

				{/* Testimonial with quotes */}
				<div className='relative flex-grow'>
					<FaQuoteLeft className='absolute top-0 left-0 text-4xl text-e_yellow opacity-50 -translate-x-2 -translate-y-2 select-none' />
					<p
						className={`text-sm text-gray-600 px-8 pt-2 line-clamp-4 h-full select-none ${showAltLanguage && isAltRtl ? 'text-right' : 'text-left'}`}
						dir={showAltLanguage && isAltRtl ? 'rtl' : 'ltr'}>
						{truncateText(currentText, 120)}
					</p>
				</div>

				{/* Footer buttons */}
				<div className='flex justify-between mt-2  transition-opacity duration-300 flex-shrink-0'>
					{hasAltLanguage && (
						<button
							onClick={() => setShowAltLanguage(!showAltLanguage)}
							className='select-none opacity-0 group-hover:opacity-100 text-xs text-blue-500 hover:text-blue-600 transition'>
							{showAltLanguage ? 'English' : 'Original'}
						</button>
					)}
					<button
						onClick={() => setIsOverlayOpen(true)}
						className={`select-none text-xs text-blue-500 hover:text-blue-600 transition ${!hasAltLanguage ? 'ml-auto' : ''}`}>
						Read More
					</button>
				</div>
			</div>

			<Overlay isOpen={isOverlayOpen} onClose={() => setIsOverlayOpen(false)}>
				<div className='flex flex-col gap-6'>
					<TestimonyHeader
						currentName={currentName}
						photoUrl={photoUrl}
						showAltLanguage={hasAltLanguage && showAltLanguage}
						isAltRtl={isAltRtl}
						size='large'
					/>
					<div className='relative'>
						<FaQuoteLeft className='absolute top-0 left-0 text-5xl text-indigo-200 opacity-50 -translate-x-2 -translate-y-2 select-none' />
						<p
							className={`text-lg text-gray-600 px-16 pt-6 select-none ${showAltLanguage && isAltRtl ? 'text-right' : 'text-left'}`}
							dir={showAltLanguage && isAltRtl ? 'rtl' : 'ltr'}>
							{currentText}
						</p>
						<FaQuoteRight className='absolute bottom-0 right-0 text-5xl text-indigo-200 opacity-50 translate-x-2 translate-y-2 select-none' />
					</div>
					{hasAltLanguage && (
						<button onClick={() => setShowAltLanguage(!showAltLanguage)} className='self-start text-sm text-blue-500 hover:text-blue-600 transition mt-4'>
							{showAltLanguage ? 'Read in English' : 'Read Original'}
						</button>
					)}
				</div>
			</Overlay>
		</>
	)
}

export default TestimonyCard
